

    .hero {
        position: relative;
        height: 150px;

        .background {
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;

            z-index: 0;
            overflow: hidden;


            .background-inner {

                height: calc(100% + 40px);
                width: calc(100% + 40px);
                left: calc(50% - 20px);
                top: calc(50% - 20px);

                background-size: cover;
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }
        }

        .background-overlay {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,0.35);
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            height: 100%;
        }
    }



