


    .product-slideshow-nav {
        li {

            a {
                height: 50px;
                width: 70px;
                display: inline-block;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    .old-price {
        text-decoration: line-through;
    }

    .product-extra-info-switcher {
        border: 1px solid var(--global-border);
        border-top: none;
    }
